import { FETCH_BANNERS, FETCH_IMG_BANNER, GET_BANNERS, GET_IMG_BANNER } from "../actions/types.js";

const initialState = {
  banners: [],
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  let banners_aux;
  let findPos;
  let pos;
  let findPos2;
  let pos2;
  switch (action.type) {
    case FETCH_BANNERS:
      return {
        banners: [],
        isLoading: true,
      };

    case GET_BANNERS:
      return {
        ...state,
        banners: action.payload.banners,
        isLoading: false,
      };

    case FETCH_IMG_BANNER:
      banners_aux = state.banners.slice();

      findPos = (elem) => elem.id_banner === action.id_banner;
      pos = banners_aux.findIndex(findPos);

      findPos2 = (elem) => elem.orden === action.numero_imagen;
      pos2 = banners_aux[pos].imagenes.findIndex(findPos2);

      banners_aux[pos].imagenes[pos2].isLoading = true;
      return {
        ...state,
        banners: banners_aux,
        isLoading: true,
      };
      
    case GET_IMG_BANNER:
      banners_aux = state.banners.slice();

      findPos = (elem) => elem.id_banner === action.id_banner;
      pos = banners_aux.findIndex(findPos);

      findPos2 = (elem) => elem.orden === action.numero_imagen;
      pos2 = banners_aux[pos].imagenes.findIndex(findPos2);

      banners_aux[pos].imagenes[pos2].imagen = action.payload;
      banners_aux[pos].imagenes[pos2].isLoading = false;

      return {
        ...state,
        banners: banners_aux,
        isLoading: false,
      };
    default:
      return state;
  }
}
