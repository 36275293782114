import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../../actions/auth";
import config from "../../config";
import { ordenarMenu } from "../../helpers/funciones";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faMapPin } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

export class Footer extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
    portal: PropTypes.object.isRequired,
    menus: PropTypes.array.isRequired,
  };

  render() {
    const { isAuthenticated } = this.props.auth;
    const { portal } = this.props;
    let menus = this.props.menus;

    menus.sort(ordenarMenu);

    if (
      menus !== undefined &&
      menus.length > 0 &&
      this.props.auth.user !== null &&
      this.props.auth.user.puede_crear_usuarios !== "S"
    ) {
      menus = menus.filter((menu) => {
        return menu.programa !== "registroHijos";
      });
    }

    const enlaces_disponibles = (
      <Fragment>
        {menus !== undefined && menus.length > 0
          ? menus.map((menu) => {
              let nombre = menu.nombre.toLowerCase();
              nombre = nombre.charAt(0).toUpperCase() + nombre.slice(1)
              menu.hijos.sort(ordenarMenu);
              return menu.visible === "S" &&
                menu.nombre !== "CARRITO" &&
                menu.nombre !== "USUARIO" ? (
                <Fragment key={menu.id}>
                  {menu.hijos.length > 0 ? (
                    <Fragment>
                      {menu.hijos.map((hijo) => {
                        return hijo.visible === "S" ? (
                          <li key={hijo.id}>
                            <Link
                              to={
                                "/" +
                                hijo.programa +
                                (hijo.camino !== null ? "/" + hijo.camino : "")
                              }
                            >
                              {hijo.nombre}
                            </Link>
                          </li>
                        ) : (
                          ""
                        );
                      })}
                    </Fragment>
                  ) : (
                    <li key={menu.id}>
                      <Link
                        to={
                          "/" +
                          menu.programa +
                          (menu.camino !== null ? "/" + menu.camino : "")
                        }
                      >
                        {nombre}
                      </Link>
                    </li>
                  )}
                </Fragment>
              ) : (
                ""
              );
            })
          : ""}
        <li key={"configCookies"}>
          <Link to={"/configurarCookies"}>Configurar Cookies</Link>
        </li>
      </Fragment>
    );

    const contenido = (
      <footer className="f-mobile-dark">
        <div className="tt-footer-col tt-color-scheme-01">
          <div className="container">
            <div className="row contenido-contacto">
              <div className="col-md-3 col-lg-3 col-xl-3 grupo-disco">
                <div className="tt-mobile-collapse">
                  <h4 className="tt-collapse-title"><strong>Grupo DISCO</strong></h4>
                  <div className="tt-collapse-content">
                    <p>Empresa especializada en la distribución profesional de equipos, componentes, herramientas y accesorios para refrigeración comercial, industrial y climatización.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-lg-3 col-xl-3 contacto">
                {config.contacto.direccion !== "" ||
                config.contacto.telefono !== "" ||
                config.contacto.email !== "" ||
                config.contacto.horario !== "" ? (
                  <div className="tt-mobile-collapse">
                    <h4 className="tt-collapse-title"><strong>Contacto</strong></h4>
                    <div className="tt-collapse-content">
                      <address>
                        {config.contacto.direccion !== "" ? (
                          <div>
                            <span><FontAwesomeIcon icon={faMapPin} /></span><div>{config.contacto.direccion}</div>
                          </div>
                        ) : (
                          ""
                        )}
                        {config.contacto.telefono !== "" ? (
                          <div>
                            <span className="telefono-footer"><FontAwesomeIcon icon={faPhone}/></span><div>{config.contacto.telefono}</div>
                          </div>
                        ) : (
                          ""
                        )}
                        {config.contacto.horario !== "" ? (
                          <p>
                            <span>Horario:</span> {config.contacto.horario}
                          </p>
                        ) : (
                          ""
                        )}
                        {config.contacto.email !== "" ? (
                          <div>
                            <span><FontAwesomeIcon icon={faEnvelope} /></span>
                            <div>
                              <a href={config.contacto.email}>
                                {config.contacto.email}
                              </a>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </address>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <ul className="tt-social-icon">
                  {config.redes_sociales.facebook !== "" ? (
                    <li>
                      <a
                        href={config.redes_sociales.facebook}
                        target="blank"
                        className="icon-g-64"
                      >
                        <span className="ocultar">Facebook</span>
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {config.redes_sociales.twitter !== "" ? (
                    <li>
                      <a
                        href={config.redes_sociales.twitter}
                        target="blank"
                        className="icon-h-58"
                      >
                        <span className="ocultar">Twitter</span>
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {config.redes_sociales.google_plus !== "" ? (
                    <li>
                      <a
                        href={config.redes_sociales.google_plus}
                        target="blank"
                        className="icon-g-66"
                      >
                        <span className="ocultar">Google Plus</span>
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {config.redes_sociales.instagram !== "" ? (
                    <li>
                      <a
                        href={config.redes_sociales.instagram}
                        target="blank"
                        className="icon-g-67"
                      >
                        <span className="ocultar">Instagram</span>
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {config.redes_sociales.linkedin !== "" ? (
                    <li>
                      <a
                        href={config.redes_sociales.linkedin}
                        target="blank"
                        className="icon-g-70"
                      >
                        <span className="ocultar">Linkedin</span>
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {config.redes_sociales.coorporativa !== "" ? (
                    <li>
                      <a
                        href={config.redes_sociales.coorporativa}
                        target="blank"
                        className="icon-e-40"
                      >
                        <span className="ocultar">Web coorporativa</span>
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </div>
              <div className="col-md-3 col-lg-3 col-xl-3 contenido">
                <div className="tt-mobile-collapse">
                  <h4 className="tt-collapse-title"><strong>Información</strong></h4>
                  <div className="tt-collapse-content">
                    <ul className="tt-list">
                      <li>
                        <Link to="/">Inicio</Link>
                      </li>
                      {enlaces_disponibles}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-md-3 col-lg-3 col-xl-3 legal">
                <div className="tt-mobile-collapse">
                  <h4 className="tt-collapse-title"><strong>Legal</strong></h4>
                  <div className="tt-collapse-content">
                    <ul className="tt-list">
                      <li>
                        <Link to="http://www.grupodisco.com/aviso-legal/">Aviso legal</Link>
                      </li>
                      <li>
                        <Link to="https://www.grupodisco.com/politica-de-privacidad/">Política de privacidad</Link>
                      </li>
                      <li>
                        <Link to="https://www.grupodisco.com/politica-de-cookies/">Política de cookies</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tt-footer-custom tt-color-scheme-04">
          <div className="container">
            <div className="tt-row">
              <div className="tt-col-left">
                <div className="tt-col-item">
                  <div className="tt-box-copyright">
                    &copy;{" "}
                    {config.copyright !== ""
                      ? config.copyright + "  " + new Date().getFullYear()
                      : "Edisa  " + new Date().getFullYear()}{" "}
                    Todos los derechos reservados.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );

    return (
      <Fragment>
        {config.footer.publico ? contenido : isAuthenticated ? contenido : ""}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  portal: state.portal.portal,
  menus: state.portal.menus,
});

export default connect(mapStateToProps, { logout })(Footer);
