import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faPhone, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getAvisos } from "../../actions/avisos";
import { getNovedades } from "../../actions/novedades";
import { getOfertas } from "../../actions/ofertas";
import { getPedidoActivo } from "../../actions/pedidos";
import { getTiras } from "../../actions/tiras";
import { ordenarMenu } from "../../helpers/funciones";
import AnalyticsEdisa from "../common/AnalyticsEdisa";
import Carrito from "../ecommerce/Carrito";

export class Header extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    pedido: PropTypes.object.isRequired,
    portal: PropTypes.object.isRequired,
    menus: PropTypes.array.isRequired,
    getAvisos: PropTypes.func.isRequired,
    getPedidoActivo: PropTypes.func.isRequired,
    getOfertas: PropTypes.func.isRequired,
    getNovedades: PropTypes.func.isRequired,
  };

  componentDidUpdate(prevProps, prevState) {
    if (typeof window.wookie !== undefined) {
      window.wookie();
    } else {
      console.log("No se encuentra librería wookie");
    }

    if (prevProps.menus !== this.props.menus || prevProps.portal !== this.props.portal) {
      //si los menus cambian
      let findPos = (elem) => elem.programa === "articulos";
      if (this.props.menus !== undefined && this.props.portal.descripcion !== undefined) {
        if (this.props.menus.some((menu) => menu.programa === "novedadesOfertas")) {
          if (this.props.ofertas.articulos === undefined) {
            this.props.getOfertas();
          }
          if (this.props.novedades.articulos === undefined) {
            this.props.getNovedades();
          }
        }
        let analytics = new AnalyticsEdisa();
        analytics.registraPagina("/");

        let pos = this.props.menus.findIndex(findPos);
        if (pos !== -1) {
          this.props.getAvisos();
          this.props.getPedidoActivo(this.props.auth.hash != null ? this.props.auth.hash : "", "N", this.props.id_pedido_seleccionado);
          this.props.getTiras();
        }
      }
    }
  }

  ocultarMenu = (e) => {
    document.getElementsByClassName("mobile-main-menu")[0].classList.remove("mmitemopen");
  };

  render() {
    const { isAuthenticated, user } = this.props.auth;
    const { lineas } = this.props.pedido;
    const { portal } = this.props;
    let ofertas = this.props.ofertas.articulos_total;
    let novedades = this.props.novedades.articulos_total;
    let menus = this.props.menus;
    const invitado = this.props.auth.invitado;

    if (menus !== undefined && menus.length > 0) {
      menus.sort(ordenarMenu);
      if (this.props.auth.isAuthenticated && !invitado) {
        menus = menus.filter((menu) => {
          return menu.programa !== "login" && menu.programa !== "registro";
        });
      }
      if (this.props.auth.user !== null && this.props.auth.user.puede_crear_usuarios !== "S") {
        menus = menus.filter((menu) => {
          return menu.programa !== "registroHijos";
        });
      }
    }

    const desktoLinks = (
      <div className="tt-obj-menu">
        <div className="row menu-secciones no-gutters">
          <div className="tt-desctop-parent-menu tt-parent-box">
            <div className="tt-desctop-menu">
              <nav>
                <ul>
                  {menus !== undefined && menus.length > 0
                    ? menus.map((menu) => {
                        menu.hijos.sort(ordenarMenu);
                        return menu.visible === "S" &&
                          menu.nombre !== "CARRITO" &&
                          menu.nombre !== "USUARIO" &&
                          menu.programa !== "login" &&
                          menu.programa !== "registro" ? (
                          <li key={menu.id} className="dropdown  tt-megamenu-col-01">
                            {menu.programa && menu.programa.substring(0, 4) === "http" ? (
                              <a href={menu.programa}>{menu.nombre}</a>
                            ) : menu.programa === null && menu.hijos && menu.hijos.length > 0 ? (
                              <Link to={"/" + menu.hijos[0].programa + (menu.hijos[0].camino ? "/" + menu.hijos[0].camino : "")}>{menu.nombre}</Link>
                            ) : (
                              <Link to={"/" + menu.programa + (menu.camino !== null ? "/" + menu.camino : "")}>{menu.nombre}</Link>
                            )}
                            {menu.programa === "novedadesOfertas" &&
                            ofertas !== undefined &&
                            ofertas !== null &&
                            novedades !== undefined &&
                            novedades !== null &&
                            (ofertas !== 0 || novedades !== 0) ? (
                              <span className="tt-badge-news">{ofertas !== 0 || novedades !== 0 ? ofertas + novedades : ""}</span>
                            ) : (
                              ""
                            )}
                            {menu.hijos.length > 0 ? (
                              <Fragment>
                                <div className="dropdown-menu">
                                  <div className="row tt-col-list">
                                    <div className="col">
                                      <ul className="tt-megamenu-submenu">
                                        {menu.hijos.map((hijo) => {
                                          return hijo.visible === "S" ? (
                                            <li key={hijo.id}>
                                              <Link to={"/" + hijo.programa + (hijo.camino !== null ? "/" + hijo.camino : "")}>{hijo.nombre}</Link>
                                            </li>
                                          ) : (
                                            ""
                                          );
                                        })}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </Fragment>
                            ) : (
                              ""
                            )}
                          </li>
                        ) : (
                          ""
                        );
                      })
                    : ""}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    );

    const desktopAuthObjects = (
      <div className="tt-obj-options obj-move-right">
        {menus !== undefined && menus.length > 0
          ? menus.map((menu) => {
              return menu.visible === "S" && menu.nombre === "CARRITO" ? (
                <>
                  <div key="boton_carrito_pres" className="tt-desctop-parent-cart tt-parent-box">
                    {this.props.tipoLineaPedidos === "P" || this.props.tipoLineaPedidos === "A" ? (
                      <div
                        className="tt-cart tt-dropdown-obj"
                        //data-tooltip="Carrito"
                        data-tposition="bottom"
                      >
                        <button className="tt-dropdown-toggle">
                          {menu.icono !== null ? <i className="icon-f-47"></i> : ""}
                          <span className="tt-badge-cart">{lineas !== undefined ? lineas.filter((linea) => linea.tipo_linea === "P").length : "0"}</span>
                        </button>
                        <Carrito mostrar_pedido_o_presupuesto="P" />
                      </div>
                    ) : (
                      ""
                    )}

                    {this.props.tipoLineaPedidos === "O" || this.props.tipoLineaPedidos === "A" ? (
                      <div
                        className="tt-cart tt-dropdown-obj"
                        //data-tooltip="Carrito"
                        data-tposition="bottom"
                      >
                        <button className="tt-dropdown-toggle">
                          {menu.icono !== null ? <i className="icon-e-91"></i> : ""}
                          <span className="tt-badge-cart">{lineas !== undefined ? lineas.filter((linea) => linea.tipo_linea === "O").length : "0"}</span>
                        </button>
                        <Carrito mostrar_pedido_o_presupuesto="O" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              ) : (
                ""
              );
            })
          : ""}
        {menus !== undefined && menus.length > 0
          ? menus.map((menu) => {
              if (menu.visible === "S" && menu.nombre === "USUARIO") {
                menu.hijos.sort(ordenarMenu);
              }
              return menu.visible === "S" && menu.nombre === "USUARIO" && !invitado ? (
                <div key="boton_usuario" className="tt-desktop-header tt-desctop-parent-account tt-parent-box">
                  <div className="tt-account tt-dropdown-obj">
                    <button
                      className="tt-dropdown-toggle"
                      //data-tooltip="Mi cuenta"
                      data-tposition="bottom"
                    >
                      {menu.icono !== null ? <i className={menu.icono}></i> : ""} <small>{isAuthenticated ? user.usuario_web : ""}</small>
                    </button>
                    <div className="tt-dropdown-menu micuenta">
                      <div className="tt-mobile-add">
                        <button className="tt-close">Cerrar</button>
                      </div>
                      <div className="tt-dropdown-inner micuenta">
                        <ul>
                          {menu.hijos.map((hijo) => {
                            return hijo.visible === "S" ? (
                              <li key={hijo.id}>
                                <Link to={"/" + hijo.programa}>
                                  {hijo.icono !== null ? <i className={hijo.icono}></i> : ""}
                                  {hijo.nombre}
                                </Link>
                              </li>
                            ) : (
                              ""
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              );
            })
          : ""}
      </div>
    );

    const mobileMenu = (
      <ul className="menu-mobile">
        {menus !== undefined && menus.length > 0
          ? menus.map((menu) => {
              menu.hijos.sort(ordenarMenu);
              return menu.visible === "S" && menu.nombre !== "CARRITO" && menu.nombre !== "USUARIO" ? (
                <li key={menu.id}>
                  {menu.programa && menu.programa.substring(0, 4) === "http" ? (
                    <a href={menu.programa}>{menu.nombre}</a>
                  ) : menu.programa === null && menu.hijos && menu.hijos.length > 0 ? (
                    <Link to={"/" + menu.hijos[0].programa + (menu.hijos[0].camino ? "/" + menu.hijos[0].camino : "")}>{menu.nombre}</Link>
                  ) : (
                    <Link onClick={this.ocultarMenu} to={"/" + menu.programa + (menu.camino !== null ? "/" + menu.camino : "")}>
                      {menu.nombre}
                    </Link>
                  )}
                  {menu.hijos.length > 0 ? (
                    <ul>
                      {menu.hijos.map((hijo) => {
                        return hijo.visible === "S" ? (
                          <li key={hijo.id}>
                            <Link onClick={this.ocultarMenu} to={"/" + hijo.programa + (hijo.camino !== null ? "/" + hijo.camino : "")}>
                              {hijo.nombre}
                            </Link>
                          </li>
                        ) : (
                          ""
                        );
                      })}
                    </ul>
                  ) : (
                    ""
                  )}
                </li>
              ) : (
                ""
              );
            })
          : ""}
      </ul>
    );
    return (
      <header id="cabecera-principal">
        <div className="header-top row justify-content-md-end no-gutters">
          <div className="telefono">
            <FontAwesomeIcon icon={faPhone} /> <span>91 151 61 00</span>
          </div>
          <div className="email">
            <FontAwesomeIcon icon={faEnvelope} /> <span>afrisa@grupodisco.com</span>
          </div>
          {desktopAuthObjects}
        </div>
        <nav className="panel-menu mobile-main-menu">
          <div className="mm-navbtn-names">
            <div className="mm-closebtn">
              <FontAwesomeIcon icon={faTimes} className="m-2" />
            </div>
          </div>
          {mobileMenu}
        </nav>
        <div className="tt-mobile-header">
          <div className="container-fluid">
            <div className="tt-header-row">
              <div className="tt-mobile-parent-menu">
                <div className="tt-logo-container">
                  <Link to={"/"} className="tt-logo tt-logo-alignment">
                    {portal.logo !== null ? (
                      <img src={"data:image/png;base64, " + portal.logo} alt="" className="loading logo-img" data-was-processed="true" />
                    ) : (
                      <span className="tt-title">{portal.descripcion}</span>
                    )}
                  </Link>
                </div>
              </div>
              <div className="tt-mobile-parent-cart tt-parent-box"></div>
              <div className="tt-mobile-parent-account tt-parent-box"></div>
              <div className="tt-menu-toggle">
                <i className="icon-03"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="tt-desktop-header">
          <div className="tt-color-scheme-01">
            <div className="container-fluid">
              <div className="tt-header-holder">
                <div className="tt-obj-logo">
                  <Link to={"/"} className="tt-logo tt-logo-alignment">
                    {portal.logo !== null ? (
                      <img src={"data:image/png;base64, " + portal.logo} alt="" className="loading logo-img" data-was-processed="true" />
                    ) : (
                      <span className="tt-title">{portal.descripcion}</span>
                    )}
                  </Link>
                </div>
                {desktoLinks}
              </div>
            </div>
          </div>
        </div>
        <div className="tt-stuck-nav">
          <div className="container">
            <div className="tt-header-row ">
              <div className="tt-stuck-parent-menu"></div>
              <div className="tt-stuck-parent-search tt-parent-box"></div>
              <div className="tt-stuck-parent-cart tt-parent-box"></div>
              <div className="tt-stuck-parent-account tt-parent-box"></div>
              <div className="tt-stuck-parent-multi tt-parent-box"></div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  pedido: state.pedidos.pedido,
  portal: state.portal.portal,
  menus: state.portal.menus,
  cookies: state.portal.cookies,
  id_pedido_seleccionado: state.pedidos.id_pedido_seleccionado,
  ofertas: state.listadoArticulos.ofertas,
  novedades: state.listadoArticulos.novedades,
  tipoLineaPedidos: state.auth.user?.tipo_linea_pedidos ?? state.portal.portal.tipo_linea_pedidos, // Si no hay dato para el usuario se usa el dato del portal
});

export default connect(mapStateToProps, {
  getAvisos,
  getPedidoActivo,
  getTiras,
  getOfertas,
  getNovedades,
})(Header);
