import {
  CERRAR_PEDIDO,
  DELETE_LINEA_PEDIDO,
  DELETE_PEDIDO,
  FETCH_PEDIDO_ACTIVO,
  FETCH_PEDIDO_ACTIVO_FAIL,
  FETCH_TEXTOS_VENTAS,
  GENERA_PAGO_PASARELA,
  GENERA_PAGO_PASARELA_SUCCESS,
  GET_PEDIDO_ACTIVO,
  GET_PERMISO_FECHA_ENTREGA,
  GET_PERMISO_SERVIR_COMPLETO,
  GET_TEXTOS_VENTAS,
  INTEGRANDO_PEDIDO,
  INTEGRAR_PEDIDO,
  INTEGRAR_PEDIDO_FAIL,
  POST_LINEAS_PEDIDO,
  POST_LINEA_PEDIDO,
  POST_PEDIDO,
  SET_COD_POSTAL_DOM_ENVIO_MOD,
  SET_DIRECCION_DOM_ENVIO_MOD,
  SET_EMAIL_PEDIDO,
  SET_ESTADO_DOM_ENVIO_MOD,
  SET_FECHA_ENTREGA,
  SET_ID_PEDIDO_SELECCIONADO,
  SET_LOCALIDAD_DOM_ENVIO_MOD,
  SET_NOMBRE_DOM_ENVIO_MOD,
  SET_NUMERO_PEDIDO_CLIENTE,
  SET_OBSERVACIONES_PEDIDO,
  SET_PERSONA_PEDIDO,
  SET_PROVINCIA_DOM_ENVIO_MOD,
  SET_SERVIR_COMPLETO,
  SET_TIPO_PORTES_DOM_ENVIO_MOD,
  SET_RESERVADO_CHAR_3,
  SET_RESERVADO_CHAR_4,
} from "../actions/types.js";

const initialState = {
  pedido: {},
  serie_pedido: "",
  num_pedido: "",
  numero_serie_pres: "",
  numero_pres: "",
  ejercicio: "",
  texto_venta: {},
  isLoading: false,
  id_pedido_seleccionado: "",
  permiso_fecha_entrega: false,
  permiso_servir_completo: false,
};

export default function reducer(state = initialState, action) {
  let ped = JSON.parse(JSON.stringify(state.pedido));

  switch (action.type) {
    case GENERA_PAGO_PASARELA:
    case FETCH_PEDIDO_ACTIVO:
    case INTEGRANDO_PEDIDO:
      return {
        ...state,
        isLoading: true,
      };

    case INTEGRAR_PEDIDO_FAIL:
    case FETCH_PEDIDO_ACTIVO_FAIL:
    case GENERA_PAGO_PASARELA_SUCCESS:
    case POST_PEDIDO:
      return {
        ...state,
        isLoading: false,
      };

    case GET_PEDIDO_ACTIVO:
      const servir_completo = state.pedido.servir_completo;
      const recogerEnTienda = state.pedido.reservado_char_4;
      const cuantoAntes = state.pedido.reservado_char_3;
      const nombre_dom_envio = state.pedido.nombre_dom_envio;
      const cod_postal_dom_envio = state.pedido.cod_postal_dom_envio;
      const direccion_dom_envio = state.pedido.direccion_dom_envio;
      const estado_dom_envio = state.pedido.estado_dom_envio;
      const localidad_dom_envio = state.pedido.localidad_dom_envio;
      const provincia_dom_envio = state.pedido.provincia_dom_envio;
      const tipo_portes_dom_envio = state.pedido.tipo_portes_dom_envio;
      const persona_pedido = state.pedido.persona_pedido;
      const observaciones = state.pedido.observaciones;
      const num_pedido_cliente = state.pedido.pedido_cliente;
      const email_pedido = state.pedido.email;
      const fecha_entrega = state.pedido.fecha_entrega;

      return {
        ...state,
        pedido: {
          ...action.payload,
          servir_completo: servir_completo,
          reservado_char_3: cuantoAntes,
          reservado_char_4: recogerEnTienda,
          cod_postal_dom_envio: cod_postal_dom_envio,
          direccion_dom_envio: direccion_dom_envio,
          estado_dom_envio: estado_dom_envio,
          localidad_dom_envio: localidad_dom_envio,
          provincia_dom_envio: provincia_dom_envio,
          tipo_portes_dom_envio: tipo_portes_dom_envio,
          nombre_dom_envio: nombre_dom_envio,
          persona_pedido: persona_pedido,
          observaciones: observaciones,
          pedido_cliente: num_pedido_cliente,
          email: email_pedido,
          fecha_entrega: fecha_entrega,
        },
        serie_pedido: "",
        num_pedido: "",
        numero_serie_pres: "",
        numero_pres: "",
        ejercicio: "",
        isLoading: false,
      };

    case POST_LINEA_PEDIDO:
      ped.lineas = [...ped.lineas, action.payload];
      return {
        ...state,
        pedido: { ...state.pedido, ped },
        isLoading: false,
      };

    case POST_LINEAS_PEDIDO:
      ped.lineas = ped.lineas.concat(action.payload);
      return {
        ...state,
        pedido: { ...state.pedido, ped },
        isLoading: false,
      };

    case DELETE_LINEA_PEDIDO:
      ped.lineas = ped.lineas.filter((linea) => linea.id !== Number.parseInt(action.payload));
      return {
        ...state,
        pedido: { ...state.pedido, ped },
        isLoading: false,
      };

    case INTEGRAR_PEDIDO:
      return {
        ...state,
        pedido: action.payload,
        serie_pedido: action.payload.numero_serie,
        num_pedido: action.payload.numero_pedido,
        ejercicio: action.payload.ejercicio,
        numero_pres: action.payload.numero_pres,
        numero_serie_pres: action.payload.numero_serie_pres,
        isLoading: false,
      };

    case CERRAR_PEDIDO:
      return {
        ...state,
        pedido: {},
        serie_pedido: action.payload.numero_serie,
        num_pedido: action.payload.numero_pedido,
        ejercicio: action.payload.ejercicio,
        numero_pres: action.payload.numero_pres,
        numero_serie_pres: action.payload.numero_serie_pres,
        isLoading: false,
      };

    case DELETE_PEDIDO:
      return {
        ...state,
        pedido: {},
        isLoading: false,
      };

    case FETCH_TEXTOS_VENTAS:
      return {
        ...state,
        texto_venta: {},
        isLoading: true,
      };

    case GET_TEXTOS_VENTAS:
      return {
        ...state,
        texto_venta: action.payload,
        isLoading: false,
      };

    case SET_ID_PEDIDO_SELECCIONADO:
      return {
        ...state,
        id_pedido_seleccionado: action.payload,
      };

    case GET_PERMISO_FECHA_ENTREGA:
      return {
        ...state,
        permiso_fecha_entrega: action.payload,
        isLoading: false,
      };

    case GET_PERMISO_SERVIR_COMPLETO:
      return {
        ...state,
        permiso_servir_completo: action.payload,
        isLoading: false,
      };

    case SET_OBSERVACIONES_PEDIDO:
      ped.observaciones = action.payload;
      return { ...state, pedido: ped, isLoading: false };

    case SET_FECHA_ENTREGA:
      ped.fecha_entrega = action.payload;
      return { ...state, pedido: ped, isLoading: false };

    case SET_NUMERO_PEDIDO_CLIENTE:
      ped.pedido_cliente = action.payload;
      return { ...state, pedido: ped, isLoading: false };

    case SET_PERSONA_PEDIDO:
      ped.persona_pedido = action.payload;
      return { ...state, pedido: ped, isLoading: false };

    case SET_NOMBRE_DOM_ENVIO_MOD:
      ped.nombre_dom_envio = action.payload;
      return { ...state, pedido: ped, isLoading: false };

    case SET_DIRECCION_DOM_ENVIO_MOD:
      ped.direccion_dom_envio = action.payload;
      return { ...state, pedido: ped, isLoading: false };

    case SET_LOCALIDAD_DOM_ENVIO_MOD:
      ped.localidad_dom_envio = action.payload;
      return { ...state, pedido: ped, isLoading: false };

    case SET_ESTADO_DOM_ENVIO_MOD:
      ped.estado_dom_envio = action.payload;
      return { ...state, pedido: ped, isLoading: false };

    case SET_PROVINCIA_DOM_ENVIO_MOD:
      ped.provincia_dom_envio = action.payload;
      return { ...state, pedido: ped, isLoading: false };

    case SET_COD_POSTAL_DOM_ENVIO_MOD:
      ped.cod_postal_dom_envio = action.payload;
      return { ...state, pedido: ped, isLoading: false };

    case SET_EMAIL_PEDIDO:
      ped.email = action.payload;
      return { ...state, pedido: ped, isLoading: false };

    case SET_TIPO_PORTES_DOM_ENVIO_MOD:
      ped.tipo_portes_dom_envio = action.payload;
      return { ...state, pedido: ped, isLoading: false };

    case SET_SERVIR_COMPLETO:
      ped.servir_completo = action.payload;
      return { ...state, pedido: ped, isLoading: false };

    case SET_RESERVADO_CHAR_3:
      ped.reservado_char_3 = action.payload;
      return { ...state, pedido: ped, isLoading: false };

    case SET_RESERVADO_CHAR_4:
      ped.reservado_char_4 = action.payload;
      return { ...state, pedido: ped, isLoading: false };

    default:
      return state;
  }
}
