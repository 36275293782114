import axios from "../axiosConfig";
import {
  FETCH_LISTAS_COMPRA,
  GET_LISTAS_COMPRA,
  DELETE_LISTA_COMPRA,
  DELETE_LISTA_COMPRA_SUCCESS,
  CREATE_LISTA_COMPRA,
  CREATE_LISTA_COMPRA_SUCCESS,
  SET_VER_LISTA,
  SET_VER_LISTA_SUCCESS,
  DELETE_LINEA_LISTA_COMPRA,
  GENERA_PEDIDO_LISTA,
  GENERA_PEDIDO_LISTA_SUCCESS,
  GET_PEDIDO_ACTIVO,
  ACTUALIZAR_LINEA_LISTA,
  ACTUALIZAR_LINEA_LISTA_SUCCESS,
  DELETE_LINEA_LISTA_COMPRA_SUCCESS,
  GET_LISTAS_COMPRA_FAIL,
  GENERA_PEDIDO_LISTA_FAIL,
} from "./types";
import { tokenConfig } from "./auth";
import { createMessage, returnErrors } from "./messages";

//////////////////////////////////////
//////////////CABECERA///////////////
/////////////////////////////////////

export const getListasCompra = () => (dispatch, getState) => {
  dispatch({
    type: FETCH_LISTAS_COMPRA,
  });
  axios
    .get(`/listasCompra/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_LISTAS_COMPRA,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: GET_LISTAS_COMPRA_FAIL,
      });
    });
};

export const generarPedidoListaCompra = (codigo_lista, tipo_linea) => (dispatch, getState) => {
  dispatch({
    type: GENERA_PEDIDO_LISTA,
  });
  axios
    .get(`/listasCompra/generarpedido/?lista_compra=${codigo_lista}&tipo_linea=${tipo_linea}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_PEDIDO_ACTIVO,
        payload: res.data,
      });
      dispatch({
        type: GENERA_PEDIDO_LISTA_SUCCESS,
      });

      let mensaje = "";
      if (tipo_linea === "O") {
        mensaje = "Se han añadido los artículos de la lista al presupuesto";
      } else if (tipo_linea === "P") {
        mensaje = "Se han añadido los artículos de la lista al pedido";
      }

      dispatch(
        createMessage({
          lineaAnadida: mensaje,
        })
      );
    })
    .catch((err) => {
      dispatch({
        type: GENERA_PEDIDO_LISTA_FAIL,
      });

      let mensaje = "";
      if (tipo_linea === "O") {
        mensaje = "No se han podido añadir los artículos a su presupuesto en este momento.";
      } else if (tipo_linea === "P") {
        mensaje = "No se han podido añadir los artículos a su pedido en este momento.";
      }

      if (err.response.data !== undefined) {
        console.log(err);
        dispatch(returnErrors(err.response.data, err.response.status));
      } else {
        console.log(err);
        dispatch(returnErrors(mensaje, err.response.status));
      }
    });
};

export const getLineasListaCompra = (codigo_lista) => (dispatch, getState) => {
  dispatch({
    type: SET_VER_LISTA,
  });
  let config = tokenConfig(getState);
  config.params = {
    lista_compra: codigo_lista,
  };

  axios
    .get(`/listasCompraLineas/`, config)
    .then((res) => {
      let aux = {
        codigo_lista: codigo_lista,
        lineas: res.data,
      };

      dispatch({
        type: SET_VER_LISTA_SUCCESS,
        payload: aux,
      });
    })
    .catch((err) => console.log(err));
};

// ELIMINAR LA LISTA
export const eliminarLista = (codigo_lista) => (dispatch, getState) => {
  dispatch({
    type: DELETE_LISTA_COMPRA,
  });
  axios
    .delete(`/listasCompra/${codigo_lista}/`, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ listaEliminada: "Lista Eliminada" }));
      dispatch({
        type: DELETE_LISTA_COMPRA_SUCCESS,
        payload: codigo_lista,
      });
    })
    .catch((err) => console.log(err));
};

export const crearLista = (_nombre_lista) => (dispatch, getState) => {
  dispatch({
    type: CREATE_LISTA_COMPRA,
  });

  const body = { nombre_lista: _nombre_lista };

  axios
    .post(`/listasCompra/`, body, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ listaCreada: "Lista creada" }));
      dispatch({
        type: CREATE_LISTA_COMPRA_SUCCESS,
        payload: res.data,
      });
      dispatch(
        createMessage({
          lineaAnadida: "Lista creada correctamente",
        })
      );
    })
    .catch((err) => console.log(err));
};

//////////////////////////////////////
////////////////LINEAS///////////////
/////////////////////////////////////

// // DELETE LINEA
export const eliminarLineaLista = (id) => (dispatch, getState) => {
  dispatch({
    type: DELETE_LINEA_LISTA_COMPRA,
  });
  axios
    .delete(`/listasCompraLineas/${id}/`, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ lineaListaEliminada: "Linea Eliminada" }));
      dispatch({
        type: DELETE_LINEA_LISTA_COMPRA_SUCCESS,
        payload: id,
      });
    })
    .catch((err) => console.log(err));
};

// // CREATE LINEA
export const postLineaLista = (linea) => (dispatch, getState) => {
  const body = JSON.stringify(linea);

  axios
    .post("/listasCompraLineas/", body, tokenConfig(getState))
    .then((res) => {
      dispatch(
        createMessage({
          lineaAnadida: "Se ha añadido el artículo a su lista",
        })
      );
      dispatch({
        type: ACTUALIZAR_LINEA_LISTA_SUCCESS,
        payload: { linea: res.data },
      });
    })
    .catch((err) => {
      dispatch(returnErrors("No se ha podido añadir el artículo a su lista en este momento.", err.response.status));
      console.log(err);
    });
};

// // CREATE N LINEAS
export const postLineasLista = (_lineas) => (dispatch, getState) => {
  const body = { lineas: _lineas };

  axios
    .post("/listasCompraLineas/createmany/", body, tokenConfig(getState))
    .then((res) => {
      dispatch(
        createMessage({
          lineaAnadida: "Se han añadido los artículos a la lista",
        })
      );
    })
    .catch((err) => {
      console.log(err);
      dispatch(returnErrors("No se han podido añadir los artículos a su lista en este momento.", err.response.status));
      console.log(err);
    });
};

export const actualizaLineaLista = (_linea) => (dispatch, getState) => {
  dispatch({
    type: ACTUALIZAR_LINEA_LISTA,
  });

  const body = _linea;

  axios
    .post("/listasCompraLineas/actualizalinealista/", body, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: ACTUALIZAR_LINEA_LISTA_SUCCESS,
        payload: { linea: _linea },
      });

      dispatch(
        createMessage({
          lineaAnadida: "Se ha actualizado la lista con la cantidad introducida",
        })
      );
    })
    .catch((err) => {
      console.log(err);
      dispatch(returnErrors("No se ha podido actualizar la lista", err.response.status));
      console.log(err);
    });
};
