import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { deleteLinea, getPedidoActivo, nuevoPedido, setIdPedidoSeleccionado, eliminarLineasTipo } from "../../actions/pedidos";
import { getPrecios } from "../../actions/precio";
import { cargarArrays, cargarParametros, comprobarCondiciones } from "../../helpers/funciones";
import Precio from "./Precio";

let articulos_aux = [];
let parametros_usados = ["precio_grupal", "mostrar_iva", "maxim_agrup", "tipo_precio"];
let parametros;

export class Carrito extends Component {
  static propTypes = {
    pedido: PropTypes.object.isRequired,
    deleteLinea: PropTypes.func.isRequired,
    hash: PropTypes.string,
    invitado: PropTypes.bool,
    locale: PropTypes.string.isRequired,
    moneda: PropTypes.string.isRequired,
    setIdPedidoSeleccionado: PropTypes.func.isRequired,
    nuevoPedido: PropTypes.func.isRequired,
    getPrecios: PropTypes.func.isRequired,
    mostrar_pedido_o_presupuesto: PropTypes.string, //P u O
  };

  componentDidMount() {
    parametros = cargarParametros(parametros_usados, this.props.portal.parametros_ecom, "CAR");
  }

  componentDidUpdate(prevProps, prevState) {
    //Comprobamos si solicitan precios grupalmente
    if (comprobarCondiciones("carrito", this.props.pedido, prevProps.pedido, parametros.precio_grupal, this.props.precio)) {
      articulos_aux = cargarArrays("carrito", this.props.pedido, "precios", this.props.precio);
      if (articulos_aux !== undefined && articulos_aux !== null && articulos_aux.length > 0) {
        //Solo llamamos a getPrecios si hai al menos un elemento en el array
        this.props.getPrecios(
          articulos_aux, //articulos_cantidades
          "carrito", //origen
          null, //domicilio_envio
          parametros.maxim_agrup
        );
      }
    }
  }

  onClick = (e) => {
    if (!this.props.pedido_cargando) {
      e.preventDefault();
      this.props.deleteLinea(e.target.dataset.linea, this.props.hash, this.props.id_pedido_seleccionado);
    }
  };

  ocultarCarrito = (e) => {
    if (document.getElementsByClassName("tt-dropdown-obj active").length > 0) {
      document.getElementsByClassName("tt-dropdown-obj active")[0].getElementsByClassName("tt-dropdown-menu")[0].style = "";

      document.getElementsByClassName("tt-dropdown-obj active")[0].classList.remove("active");
    }
    if (document.getElementsByTagName("body").length > 0) {
      document.getElementsByTagName("body")[0].classList.remove("tt-popup-dropdown");
    }
  };

  nuevoPedido = () => {
    this.props.setIdPedidoSeleccionado("");
    this.props.nuevoPedido();
    this.props.getPedidoActivo("", "S");
  };

  textosBotones = (operacion) => {
    // operacion -> FINALIZAR, BORRAR
    let titulo = "";
    if (this.props.mostrar_pedido_o_presupuesto === "O") titulo = `${operacion} PRESUPUESTO`;
    else titulo = `${operacion} PEDIDO`;

    return titulo;
  };

  eliminarLineasTipo = (e) => {
    if (!this.props.pedido_cargando) {
      e.preventDefault();
      this.props.eliminarLineasTipo(this.props.mostrar_pedido_o_presupuesto, this.props.hash, this.props.id_pedido_seleccionado);
    }
  };

  render() {
    return (
      <Fragment>
        <div className="tt-dropdown-menu dropdown-carrito">
          <div className="tt-mobile-add">
            <h6 className="tt-title">CARRITO</h6>
            <button className="tt-close">Cerrar</button>
          </div>
          <div className="tt-dropdown-inner">
            <div className="tt-cart-layout">
              <div className="tt-cart-content">
                <div className="info-ped-pres">
                  {this.props.pedido.lineas !== undefined &&
                  this.props.pedido.lineas.length > 0 &&
                  this.props.pedido.lineas.some((linea) => linea.tipo_linea === "P" && this.props.mostrar_pedido_o_presupuesto === "P") ? (
                    <div className="info-pedido">
                      <div>
                        <h3 className="titulo-carrito">Pedido</h3>
                      </div>
                      <div className="tt-cart-list">
                        {this.props.pedido.lineas === undefined || this.props.pedido.lineas.length === 0
                          ? ""
                          : this.props.pedido.lineas
                              .filter((linea) => {
                                if (linea.tipo_linea === "P") {
                                  return true;
                                } else if (linea.tipo_linea === "R") {
                                  return this.props.pedido.lineas.some(
                                    (linea_padre) => (linea_padre.numero_linea = linea.numero_linea_origen && linea_padre.tipo_linea === "P")
                                  );
                                } else return false;
                              })
                              .map((linea) => (
                                <div className="tt-item" key={linea.id}>
                                  {
                                    <Fragment>
                                      <div className="tt-item-descriptions">
                                        <h2 className="tt-title">{linea.articulo}</h2>
                                        <ul className="tt-add-info">
                                          <li>{linea.descripcion}</li>
                                        </ul>
                                        <div className="tt-quantity">
                                          {linea.sub_pres !== undefined && linea.sub_pres !== null && linea.sub_pres !== ""
                                            ? Number.parseFloat(linea.sub_pres_cant).toFixed(0).toString() + " X " + linea.sub_pres + " X "
                                            : Number.parseFloat(linea.cantidad_pedida).toFixed(0).toString() + " (" + linea.presentacion_pedido + ") X "}
                                        </div>
                                        <div className="tt-price">
                                          {linea.tipo_linea !== "R" ? (
                                            linea.precio_manual === "S" ? (
                                              <Precio
                                                codigo_articulo={linea.articulo}
                                                precio_manual={linea.precio_venta}
                                                mostrar_iva={parametros.mostrar_iva}
                                                tipo_precio={parametros.tipo_precio}
                                              />
                                            ) : (
                                              <span>
                                                {
                                                  <Precio
                                                    codigo_articulo={linea.articulo}
                                                    mostrar_iva={parametros.mostrar_iva}
                                                    tipo_precio={parametros.tipo_precio}
                                                  />
                                                }
                                              </span>
                                            )
                                          ) : (
                                            <Precio
                                              codigo_articulo={linea.articulo}
                                              precio_manual={0}
                                              mostrar_iva={parametros.mostrar_iva}
                                              tipo_precio={parametros.tipo_precio}
                                            />
                                          )}
                                        </div>
                                      </div>

                                      {linea.tipo_articulo !== "R" && linea.tipo_articulo !== "T" && linea.tipo_articulo !== "F" ? (
                                        <div className="tt-item-close">
                                          <a href="/#" onClick={this.onClick} data-linea={linea.id} className="tt-btn-close">
                                            <span className="ocultar">Cerrar</span>
                                          </a>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </Fragment>
                                  }
                                </div>
                              ))}
                      </div>

                      <div className="tt-cart-total-row">
                        <div className="tt-cart-total-title">TOTAL:</div>
                        <div className="tt-cart-total-price">
                          {this.props.pedido.lineas === undefined || this.props.pedido.lineas.length === 0
                            ? 0
                            : Intl.NumberFormat(this.props.locale, {
                                style: "currency",
                                currency: this.props.moneda,
                              }).format(
                                Number.parseFloat(
                                  this.props.pedido.lineas
                                    .filter((linea) => linea.tipo_linea === "P")
                                    .map((linea) => {
                                      return Number.parseFloat(linea.importe_neto_lin * linea.cantidad_pedida);
                                    })
                                    .reduce((accumulator, currentValue) => accumulator + currentValue)
                                ).toFixed(2)
                              )}
                        </div>
                      </div>
                      <div>
                        <div>
                          {" "}
                          IVA (21%):&nbsp;
                          {this.props.pedido.lineas === undefined || this.props.pedido.lineas.length === 0
                            ? 0
                            : Intl.NumberFormat(this.props.locale, {
                                style: "currency",
                                currency: this.props.moneda,
                              }).format(
                                (
                                  Number.parseFloat(
                                    this.props.pedido.lineas
                                      .filter((linea) => linea.tipo_linea === "P")
                                      .map((linea) => {
                                        return Number.parseFloat(linea.importe_neto_lin * linea.cantidad_pedida);
                                      })
                                      .reduce((accumulator, currentValue) => accumulator + currentValue)
                                  ) * 0.21
                                ).toFixed(2)
                              )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {this.props.pedido.lineas !== undefined &&
                  this.props.pedido.lineas.length > 0 &&
                  this.props.pedido.lineas.some((linea) => linea.tipo_linea === "O" && this.props.mostrar_pedido_o_presupuesto === "O") ? (
                    <div className="info-presupuesto">
                      <div>
                        <h3 className="titulo-carrito">Presupuesto</h3>
                      </div>
                      <div className="tt-cart-list">
                        {this.props.pedido.lineas === undefined || this.props.pedido.lineas.length === 0
                          ? ""
                          : this.props.pedido.lineas
                              .filter((linea) => {
                                if (linea.tipo_linea === "O") {
                                  return true;
                                } else if (linea.tipo_linea === "R") {
                                  return this.props.pedido.lineas.some(
                                    (linea_padre) => (linea_padre.numero_linea = linea.numero_linea_origen && linea_padre.tipo_linea === "O")
                                  );
                                } else return false;
                              })
                              .map((linea) => (
                                <div className="tt-item" key={linea.id}>
                                  <Fragment>
                                    <div className="tt-item-descriptions">
                                      <h2 className="tt-title">{linea.articulo}</h2>
                                      <ul className="tt-add-info">
                                        <li>{linea.descripcion}</li>
                                      </ul>
                                      <div className="tt-quantity">
                                        {linea.sub_pres !== undefined && linea.sub_pres !== null && linea.sub_pres !== ""
                                          ? Number.parseFloat(linea.sub_pres_cant).toFixed(0).toString() + " X " + linea.sub_pres + " X "
                                          : Number.parseFloat(linea.cantidad_pedida).toFixed(0).toString() + " (" + linea.presentacion_pedido + ") X "}
                                      </div>
                                      <div className="tt-price">
                                        {linea.tipo_linea !== "R" ? (
                                          linea.precio_manual === "S" ? (
                                            <Precio
                                              codigo_articulo={linea.articulo}
                                              precio_manual={linea.precio_venta}
                                              mostrar_iva={parametros.mostrar_iva}
                                              tipo_precio={parametros.tipo_precio}
                                            />
                                          ) : (
                                            <span>
                                              {
                                                <Precio
                                                  codigo_articulo={linea.articulo}
                                                  mostrar_iva={parametros.mostrar_iva}
                                                  tipo_precio={parametros.tipo_precio}
                                                />
                                              }
                                            </span>
                                          )
                                        ) : (
                                          <Precio
                                            codigo_articulo={linea.articulo}
                                            precio_manual={0}
                                            mostrar_iva={parametros.mostrar_iva}
                                            tipo_precio={parametros.tipo_precio}
                                          />
                                        )}
                                      </div>
                                    </div>

                                    <div className="tt-item-close">
                                      <a href="/#" onClick={this.onClick} data-linea={linea.id} className="tt-btn-close">
                                        <span className="ocultar">Cerrar</span>
                                      </a>
                                    </div>
                                  </Fragment>
                                </div>
                              ))}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="tt-cart-btn">
                  <div className="tt-item">
                    {this.props.pedido.lineas === undefined ||
                    (this.props.pedido.lineas.filter((linea) => linea.tipo_linea === "P").length === 0 && this.props.mostrar_pedido_o_presupuesto === "P") ? (
                      "No hay artículos en el pedido"
                    ) : this.props.pedido.lineas === undefined ||
                      (this.props.pedido.lineas.filter((linea) => linea.tipo_linea === "O").length === 0 && this.props.mostrar_pedido_o_presupuesto === "O") ? (
                      "No hay artículos en el presupuesto"
                    ) : (
                      <Fragment>
                        {this.props.invitado !== undefined && this.props.invitado === true ? (
                          <Link to="/login" className="btn" onClick={this.ocultarCarrito}>
                            LOGIN
                          </Link>
                        ) : (
                          <Fragment>
                            {!this.props.pedido_cargando ? (
                              <Link
                                to={this.props.mostrar_pedido_o_presupuesto === "O" ? "/resumen/presupuesto" : "/resumen/pedido"}
                                className="btn"
                                onClick={this.ocultarCarrito}
                              >
                                {this.textosBotones("FINALIZAR")}
                              </Link>
                            ) : (
                              <button className="btn" disabled={true}>
                                {this.textosBotones("FINALIZAR")}
                              </button>
                            )}

                            <button type="submit" disabled={this.props.pedido_cargando} onClick={this.eliminarLineasTipo} className="btn btn-nuevo-pedido">
                              {this.textosBotones("BORRAR")}
                            </button>
                          </Fragment>
                        )}
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  portal: state.portal.portal,
  articulos: state.listadoArticulos.articulos,
  pedido: state.pedidos.pedido,
  hash: state.auth.hash,
  invitado: state.auth.invitado,
  locale: state.precio.locale,
  moneda: state.precio.moneda,
  precio: state.precio.precio,
  pedido_cargando: state.pedidos.isLoading,
  id_pedido_seleccionado: state.pedidos.id_pedido_seleccionado,
});

export default connect(mapStateToProps, {
  deleteLinea,
  setIdPedidoSeleccionado,
  nuevoPedido,
  getPedidoActivo,
  getPrecios,
  eliminarLineasTipo,
})(Carrito);
