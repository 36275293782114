import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getPrecio } from "../../actions/precio";
import Spinner from "../layout/Spinner";
import { cargarParametros } from "../../helpers/funciones";

let parametros_usados = ["precio_grupal"];
let parametros;

export class Precio extends Component {
  state = {};

  static propTypes = {
    precio: PropTypes.object.isRequired,
    getPrecio: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
  };

  componentDidMount() {
    parametros = cargarParametros(parametros_usados, this.props.portal.parametros_ecom, "GEN");

    if (this.props.precio[this.props.codigo_articulo] === undefined) {
      if ((parametros.precio_grupal !== undefined && parametros.precio_grupal !== "S") || this.props.forzar_peticion === "S") {
        this.props.getPrecio(this.props.codigo_articulo, 1, this.props.id_pedido_seleccionado, this.props.pedido.domicilio_envio);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.precio[this.props.codigo_articulo] === undefined) {
      if ((parametros.precio_grupal !== undefined && parametros.precio_grupal !== "S") || this.props.forzar_peticion === "S") {
        if (prevProps.codigo_articulo !== this.props.codigo_articulo) {
          this.props.getPrecio(this.props.codigo_articulo, 1, this.props.id_pedido_seleccionado, this.props.pedido.domicilio_envio);
        }
      }
    }
  }

  mostrarPresentacion() {
    return (
      <>
        {typeof this.props.precio[this.props.codigo_articulo].presentacion === "string" &&
        this.props.precio[this.props.codigo_articulo].data.presentacion !== ""
          ? "/" + this.props.precio[this.props.codigo_articulo].data.presentacion
          : ""}
      </>
    );
  }

  mostrarImpuestos() {
    return (
      <>
        {!Array.isArray(this.props.precio[this.props.codigo_articulo].data.impuesto_articulo) &&
        this.props.precio[this.props.codigo_articulo].data.impuesto_articulo !== null &&
        this.props.mostrar_iva !== "N"
          ? " + " + this.props.precio[this.props.codigo_articulo].data.impuesto_articulo + "% IVA"
          : ""}
      </>
    );
  }

  obtenerPrecioNeto() {
    if (this.props.verPVP) {
      return (
        <Fragment>
          <span className="etiqueta-precio">
            {this.props.precio[this.props.codigo_articulo].data.precio_neto
              ? new Intl.NumberFormat(this.props.locale, {
                  style: "currency",
                  currency: this.props.precio[this.props.codigo_articulo].data.divisa_precio,
                }).format(Number.parseFloat(this.props.precio[this.props.codigo_articulo].data.precio_pvp).toFixed(2))
              : ""}

            {this.mostrarImpuestos()}

            {this.mostrarPresentacion()}
          </span>
        </Fragment>
      );
    } else {
      return (
        <span className="etiqueta-precio">
          {this.props.precio[this.props.codigo_articulo].data.precio_neto
            ? new Intl.NumberFormat(this.props.locale, {
                style: "currency",
                currency: this.props.precio[this.props.codigo_articulo].data.divisa_precio,
              }).format(
                Number.parseFloat(
                  this.props.precio[this.props.codigo_articulo].data.precio_neto
                ).toFixed(2)
              )
            : ""}

          {this.mostrarImpuestos()}

          {this.mostrarPresentacion()}
        </span>
      );
    }
  }
  obtenerPrecioTachado() {
    return (
      <span className="etiqueta-precio-tachado">
        {this.props.precio[this.props.codigo_articulo].data.precio_neto
          ? new Intl.NumberFormat(this.props.locale, {
              style: "currency",
              currency: this.props.precio[this.props.codigo_articulo].data.divisa_precio,
            }).format(Number.parseFloat(this.props.precio[this.props.codigo_articulo].data.precio_presentacion).toFixed(2))
          : ""}

        {this.mostrarImpuestos()}

        {this.mostrarPresentacion()}
      </span>
    );
  }

  obtenerPrecioBruto() {
    return (
      <span className="etiqueta-precio-bruto">
        {this.props.precio[this.props.codigo_articulo].data.precio_neto
          ? new Intl.NumberFormat(this.props.locale, {
              style: "currency",
              currency: this.props.precio[this.props.codigo_articulo].data.divisa_precio,
            }).format(
              Number.parseFloat(
                this.props.precio[this.props.codigo_articulo].data.precio_presentacion * (this.props.cantidad !== undefined ? this.props.cantidad : 1)
              ).toFixed(2)
            )
          : ""}

        {this.mostrarImpuestos()}

        {this.mostrarPresentacion()}
        {this.props.añadir_descuento !== undefined && this.props.añadir_descuento !== "null" && this.props.añadir_descuento !== "N"
          ? this.obtenerDescuento()
          : ""}
      </span>
    );
  }

  obtenerDescuento() {
    let descuento = 0;

    if (
      this.props.precio[this.props.codigo_articulo].data.precio_neto !== undefined &&
      this.props.precio[this.props.codigo_articulo].data.cantidad_precio !== undefined &&
      this.props.precio[this.props.codigo_articulo].data.precio_presentacion !== undefined &&
      Number.parseFloat(this.props.precio[this.props.codigo_articulo].data.precio_neto) !== 0 &&
      Number.parseFloat(this.props.precio[this.props.codigo_articulo].data.precio_presentacion) !== 0
    ) {
      descuento = Number.parseFloat(
        100 -
          ((Number.parseFloat(this.props.precio[this.props.codigo_articulo].data.precio_neto) /
            Number.parseFloat(this.props.precio[this.props.codigo_articulo].data.cantidad_precio)) *
            100) /
            Number.parseFloat(this.props.precio[this.props.codigo_articulo].data.precio_presentacion)
      );
    }
    if (descuento !== 0) {
      if (this.props.añadir_descuento !== undefined && this.props.añadir_descuento !== "null" && this.props.añadir_descuento !== "N") {
        return " -" + descuento.toFixed(2).toString() + "%";
      } else {
        return <span className="etiqueta-descuento">{descuento.toFixed(2).toString() + "%"}</span>;
      }
    } else {
      return "";
    }
  }

  obtenerPrecioMixto() {
    if (
      this.props.auth !== undefined &&
      this.props.auth.user !== null &&
      this.props.auth.user.netear_precio !== undefined &&
      this.props.auth.user.netear_precio !== "N" &&
      Number.parseFloat(this.props.precio[this.props.codigo_articulo].data.precio_presentacion).toFixed(2) !==
        Number.parseFloat(
          this.props.precio[this.props.codigo_articulo].data.precio_neto / this.props.precio[this.props.codigo_articulo].data.cantidad_precio
        ).toFixed(2)
    ) {
      return (
        <Fragment>
          <span className="etiqueta-precio-tachado mixto">
            {this.props.precio[this.props.codigo_articulo].data.precio_neto
              ? new Intl.NumberFormat(this.props.locale, {
                  style: "currency",
                  currency: this.props.precio[this.props.codigo_articulo].data.divisa_precio,
                }).format(Number.parseFloat(this.props.precio[this.props.codigo_articulo].data.precio_presentacion).toFixed(2))
              : ""}

            {this.mostrarImpuestos()}

            {this.mostrarPresentacion()}
          </span>
          <span className="etiqueta-precio mixto">
            {this.props.precio[this.props.codigo_articulo].data.precio_neto
              ? new Intl.NumberFormat(this.props.locale, {
                  style: "currency",
                  currency: this.props.precio[this.props.codigo_articulo].data.divisa_precio,
                }).format(
                  Number.parseFloat(
                    this.props.precio[this.props.codigo_articulo].data.precio_neto / this.props.precio[this.props.codigo_articulo].data.cantidad_precio
                  ).toFixed(2)
                )
              : ""}
          </span>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <span className="etiqueta-precio mixto">
            {this.props.precio[this.props.codigo_articulo].data.precio_neto
              ? new Intl.NumberFormat(this.props.locale, {
                  style: "currency",
                  currency: this.props.precio[this.props.codigo_articulo].data.divisa_precio,
                }).format(
                  Number.parseFloat(
                    this.props.precio[this.props.codigo_articulo].data.precio_neto / this.props.precio[this.props.codigo_articulo].data.cantidad_precio
                  ).toFixed(2)
                )
              : ""}

            {this.mostrarImpuestos()}

            {this.mostrarPresentacion()}
          </span>
        </Fragment>
      );
    }
  }

  render() {
    if (this.props.precio[this.props.codigo_articulo] === undefined || this.props.precio[this.props.codigo_articulo].isLoading) {
      return <Spinner showSpinner={true} />;
    } else if (this.props.precio[this.props.codigo_articulo].error) {
      return (
        <Fragment>
          <span>Precio no disponible</span>
        </Fragment>
      );
    } else if (this.props.precio_manual !== undefined) {
      return (
        <Fragment>
          <span>
            {this.props.precio[this.props.codigo_articulo].data.precio_neto
              ? new Intl.NumberFormat(this.props.locale, {
                  style: "currency",
                  currency: this.props.precio[this.props.codigo_articulo].data.divisa_precio,
                }).format(Number.parseFloat(this.props.precio_manual).toFixed(2))
              : ""}
            {typeof this.props.precio[this.props.codigo_articulo].presentacion === "string" &&
            this.props.precio[this.props.codigo_articulo].data.presentacion !== ""
              ? "/" + this.props.precio[this.props.codigo_articulo].data.presentacion
              : ""}
          </span>
        </Fragment>
      );
    } else if (this.props.precio[this.props.codigo_articulo] !== undefined) {
      return (
        <Fragment>
          <meta itemProp="priceCurrency" content={this.props.precio[this.props.codigo_articulo].data.divisa_precio} />
          <meta
            itemProp="price"
            content={Number.parseFloat(
              this.props.precio[this.props.codigo_articulo].data.precio_neto / this.props.precio[this.props.codigo_articulo].data.cantidad_precio
            ).toFixed(2)}
          />

          {this.props.tipo_precio !== undefined && this.props.tipo_precio !== ""
            ? this.props.tipo_precio === "neto"
              ? this.obtenerPrecioNeto()
              : this.props.tipo_precio === "descuento"
              ? this.obtenerDescuento()
              : this.props.tipo_precio === "mixto"
              ? this.obtenerPrecioMixto()
              : this.props.tipo_precio === "bruto"
              ? this.obtenerPrecioBruto()
              : this.obtenerPrecioMixto()
            : this.obtenerPrecioMixto()}
        </Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  portal: state.portal.portal,
  precio: state.precio.precio,
  locale: state.precio.locale,
  auth: state.auth,
  presentacion: state.presentaciones.presentacion,
  id_pedido_seleccionado: state.pedidos.id_pedido_seleccionado,
  pedido: state.pedidos.pedido,
});

export default connect(mapStateToProps, { getPrecio })(Precio);
